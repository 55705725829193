import React from 'react'


// type QuestionProps = {
//     question: {
//         type:string
//         name: string
//         text:string
//         description?:string
//         options:[]
//         idQ:number
//         maxlength?: undefined;
//         minlength?: undefined;
//         optional?: undefined;
//         money?:undefined
//     } | []
//     formData:{},
//     handleSetData: (e: {target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => void
// };

export const Questions = ({question, formData, handleSetData}) => {


  return (
    <>
        {
            question.type === 'radio' ?
            <div className="question" >
                    <p>{question.text} </p> 
                    {question.description?  <span>{question.description}</span> : ''}
                    <span className='msg-error' id={"msg-"+question.idQ}></span>
                    {
                        question.options.map((option, i)=>(
                            <div key={"option"+option} className="inputfield">
                                <label htmlFor={question.idQ+'-'+i}>{option.value || option}</label>
                                <input checked={formData[question.name] && formData[question.name] === option ? true :  ( option.other && !question.options.includes(formData[question.name]))?true : false} 
                                    value={ option.value || option} onChange={handleSetData} type={question.type} name={question.name} id={question.idQ+'-'+i}  required />
                                {
                                    option.other && 
                                    <input  value={ formData[question.name] ===(option.value || option) || formData[question.name] ==="NO" ? "" : formData[question.name] } onChange={handleSetData}  data-type={question.money? 'currency' : '' } max={question.max? question.max : '' } maxLength={question.maxlength? question.maxlength : '' } minLength={question.minlength? question.minLength : '' } type="text" name={question.name} id={ question.idQ} pattern={question.pattern? `${question.pattern}` : '' } autoComplete={question.autocomplete? question.autocomplete : '' } required={question.optional || formData[question.name] ==="NO" ? false: true }  />
                                }
                            </div>
                        ))

                    }
            </div>
            :
            question.type === "checkbox" ?
            <div className="question" >
                    <p>{question.text} </p> 
                    {question.description?  <span>{question.description}</span> : ''}
                    <span className='msg-error' id={"msg-"+question.idQ}></span>
                    {
                        question.options.map((option, i)=>(
                            <div key={"option"+option} className="inputfield">
                                <label htmlFor={question.idQ+'-'+i}>{option}</label>
                                <input checked={formData[question.name] && formData[question.name].includes(option) ? true : false} value={option} onChange={handleSetData} type={question.type} name={question.name} id={question.idQ+'-'+i} required={(formData['EXPORTACION_2021'] && formData['EXPORTACION_2021'] !=="0%") || (formData['EXPORTACION_2022'] && formData['EXPORTACION_2022'] !=="0%") || (formData['EXPORTACION_2023'] && formData['EXPORTACION_2023'] !=="0%")  ? true :false } />
                            </div>
                        ))

                    }
            </div>
            :
            question.type === "textarea"?
            <div className="question" >
                <label htmlFor={question.idQ+""} >{question.text}</label>
                {question.description?  <span>{question.description}</span> : '' }
                <span className='msg-error' id={"msg-"+question.idQ}></span>
                <textarea onChange={handleSetData} maxLength={300} name={question.name} id={question.idQ+''}   required></textarea >

            </div>
            :
            question.type === "select"?
            <div className="question" >
                <p>{question.text} </p> 
                {question.description?  <span>{question.description}</span> : '' }
                <span className='msg-error' id={"msg-"+question.idQ}></span>
                <select value={formData[question.name] || ""} onChange={handleSetData} name={question.name} id={question.idQ+''}  required >
                    {question.options.map((option)=>(
                        <option key={option} value={option}> {option} </option>
                        )
                    )}
                </select>

            </div>
            :
            <div className="question" >
                <label htmlFor={question.idQ+""} >{question.text} {question.optional? ' (opcional)': ''}</label>
                {question.description?  <span>{question.description}</span> : '' }
                <span className='msg-error' id={"msg-"+question.idQ}></span>
                <input value={formData[question.name] || ""} onChange={handleSetData} min="0" data-type={question.money? 'currency' : '' } max={question.max? question.max : '' } maxLength={question.maxlength? question.maxlength : '' } minLength={question.minlength? question.minLength : '' } type={question.type} name={question.name} id={ question.idQ} pattern={question.pattern? `${question.pattern}` : '' } autoComplete={question.autocomplete? question.autocomplete : '' } required={question.optional? false: true }  />

            </div>
        }
    </>
  )
}
