import React from 'react'
import { Questions } from './Questions';

// type SectionProps = {
//     section: {
//         id: number;
//         title: string;
//         description?: string;
//         questions: {
//             type:string
//             name: string
//             text:string
//             description:string
//             options:[]
//             idQ:number
//             maxlength?: undefined;
//             minlength?: undefined;
//             optional?: undefined;
//             money?:undefined
//         }[] | []
//     }
//     formData:{},
//     handleSetData: (e: {target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => void
// };


export const Section = ({section, formData, handleSetData}) => {
    
  return (
    <div className="section-form">
        <form id='sectionForm' className='sectionForm-form'>
            <div className="form-title">
                {section.title}
            </div>
            <div className="form-description">
                {section.description}
            </div>
            {
                section.questions.map(question=> (
                    <Questions key={"question"+question.idQ} formData={formData} handleSetData={handleSetData} question={question} />
                ))
            }
        </form>
    </div>
  )
}
