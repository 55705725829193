export const actividades={
    "10 Comercio":{
            sector: "COMERCIO",
            subsector:"10 Comercio",
            ramo: "10 Comercio"
    },
    "11 Servicios":{
            sector: "SERVICIOS",
            subsector:"11 Servicios",
            ramo: "11 Servicios"
    },
    "12 Tratamiento de agua para uso industrial":{
            sector: "OTRAS INDUSTRIAS",
            subsector:"12 Tratamiento de agua para uso industrial",
            ramo: "12 Tratamiento de agua para uso industrial"
    },
    "13 Electricidad":{
            sector: "OTRAS INDUSTRIAS",
            subsector:"13 Electricidad",
            ramo: "13 Electricidad"
    },
    "15 Beneficios de Minerales":{
            sector: "OTRAS INDUSTRIAS",
            subsector:"15 Beneficios de Minerales",
            ramo: "15 Beneficios de Minerales"
    },
    "21 Mineria":{
            sector: "OTRAS INDUSTRIAS",
            subsector:"21 Mineria",
            ramo: "21 Mineria"
    },
    "22 Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos para el consumidor final":{
            sector: "OTRAS INDUSTRIAS",
            subsector:"22 Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos para el consumidor final",
            ramo: "22 Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos para el consumidor final"
    },
    "23 CONSTRUCCIÓN":{
            sector: "INDUSTRIA",
            subsector:"23 CONSTRUCCIÓN",
            ramo: "23 CONSTRUCCIÓN"
    },
    "43 Comercio al por mayor":{
            sector: "COMERCIO",
            subsector:"43 Comercio al por mayor",
            ramo: "43 Comercio al por mayor"
    },
    "46 Comercio al por menor":{
            sector: "COMERCIO",
            subsector:"46 Comercio al por menor",
            ramo: "46 Comercio al por menor"
    },
    "48 Transportes, correos y almacenamiento":{
            sector: "SERVICIOS",
            subsector:"48 Transportes, correos y almacenamiento",
            ramo: "48 Transportes, correos y almacenamiento"
    },
    "51 Información en medios masivos":{
            sector: "SERVICIOS",
            subsector:"51 Información en medios masivos",
            ramo: "51 Información en medios masivos"
    },
    "52 Servicios financieros y de seguros":{
            sector: "SERVICIOS",
            subsector:"52 Servicios financieros y de seguros",
            ramo: "52 Servicios financieros y de seguros"
    },
    "53 Servicios inmobiliarios y de alquiler de bienes muebles e intangibles":{
            sector: "SERVICIOS",
            subsector:"53 Servicios inmobiliarios y de alquiler de bienes muebles e intangibles",
            ramo: "53 Servicios inmobiliarios y de alquiler de bienes muebles e intangibles"
    },
    "54 Servicios profesionales, científicos y técnicos":{
            sector: "SERVICIOS",
            subsector:"54 Servicios profesionales, científicos y técnicos",
            ramo: "54 Servicios profesionales, científicos y técnicos"
    },
    "56 Servicios de apoyo a los negocios y manejo de residuos y desechos, y servicios de remediación":{
            sector: "SERVICIOS",
            subsector:"56 Servicios de apoyo a los negocios y manejo de residuos y desechos, y servicios de remediación",
            ramo: "56 Servicios de apoyo a los negocios y manejo de residuos y desechos, y servicios de remediación"
    },
    "61 Servicios educativos":{
            sector: "SERVICIOS",
            subsector:"61 Servicios educativos",
            ramo: "61 Servicios educativos"
    },
    "62 Servicios de salud y asistencia social":{
            sector: "SERVICIOS",
            subsector:"62 Servicios de salud y asistencia social",
            ramo: "62 Servicios de salud y asistencia social"
    },
    "71 Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos":{
            sector: "SERVICIOS",
            subsector:"71 Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
            ramo: "71 Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos"
    },
    "72 Servicios de alojamiento temporal y de preparación de alimentos y bebidas":{
            sector: "SERVICIOS",
            subsector:"72 Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
            ramo: "72 Servicios de alojamiento temporal y de preparación de alimentos y bebidas"
    },
    "81 Otros servicios excepto actividades gubernamentales":{
            sector: "SERVICIOS",
            subsector:"81 Otros servicios excepto actividades gubernamentales",
            ramo: "81 Otros servicios excepto actividades gubernamentales"
    },
    "3111 Elaboración de alimentos para animales":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3112 Molienda de granos y de semillas y obtención de aceites y grasas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3113 Elaboración de azúcares, chocolates, dulces y similares":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3114 Conservación de frutas, verduras, guisos y otros alimentos preparados":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3115 Elaboración de productos lácteos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3116 Matanza, empacado y procesamiento de carne de ganado, aves y otros animales comestibles":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3117 Preparación y envasado de pescados y mariscos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3118 Elaboración de productos de panadería y tortillas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3119 Otras industrias alimentarias":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "311 INDUSTRIA ALIMENTARIA"
    },
    "3121 Industria de las bebidas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "312 INDUSTRIA DE LAS BEBIDAS Y DEL TABACO"
    },
    "3122 Industria del tabaco":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "312 INDUSTRIA DE LAS BEBIDAS Y DEL TABACO"
    },
    "3131 Preparación e hilado de fibras textiles, y fabricación de hilos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "313 FABRICACIÓN DE INSUMOS TEXTILES Y ACABADOS TEXTILES"
    },
    "3132 Fabricación de telas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "313 FABRICACIÓN DE INSUMOS TEXTILES Y ACABADOS TEXTILES"
    },
    "3133 Acabado de productos textiles y fabricación de telas recubiertas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "313 FABRICACIÓN DE INSUMOS TEXTILES Y ACABADOS TEXTILES"
    },
    "3141 Confección de alfombras, blancos y similares":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "314 FABRICACIÓN DE PRODUCTOS TEXTILES, EXCEPTO PRENDAS DE VESTIR"
    },
    "3149 Fabricación de otros productos textiles, excepto prendas de vestir":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "314 FABRICACIÓN DE PRODUCTOS TEXTILES, EXCEPTO PRENDAS DE VESTIR"
    },
    "3151 Fabricación de prendas de vestir de tejido de punto":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "315 FABRICACIÓN DE PRENDAS DE VESTIR"
    },
    "3152 Confección de prendas de vestir":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "315 FABRICACIÓN DE PRENDAS DE VESTIR"
    },
    "3159 Confección de accesorios de vestir y otras prendas de vestir no clasificados en otra parte":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "315 FABRICACIÓN DE PRENDAS DE VESTIR"
    },
    "3161 Curtido y acabado de cuero y piel":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "316 CURTIDO Y ACABADO DE CUERO Y PIEL, Y FABRICACIÓN DE PRODUCTOS DE CUERO, PIEL Y MATERIALES SUCEDANEOS"
    },
    "3162 Fabricación de calzado":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "316 CURTIDO Y ACABADO DE CUERO Y PIEL, Y FABRICACIÓN DE PRODUCTOS DE CUERO, PIEL Y MATERIALES SUCEDANEOS"
    },
    "3169 Fabricación de otros productos de cuero, piel y materiales sucedáneos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "316 CURTIDO Y ACABADO DE CUERO Y PIEL, Y FABRICACIÓN DE PRODUCTOS DE CUERO, PIEL Y MATERIALES SUCEDANEOS"
    },
    "3211 Aserrado y conservación de la madera":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "321 INDUSTRIA DE LA MADERA"
    },
    "3212 Fabricación de laminados y aglutinados de madera":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "321 INDUSTRIA DE LA MADERA"
    },
    "3219 Fabricación de otros productos de madera":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "321 INDUSTRIA DE LA MADERA"
    },
    "3221 Fabricación de pulpa, papel y cartón":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "322 INDUSTRIA DEL PAPEL"
    },
    "3222 Fabricación de productos de cartón y papel":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "322 INDUSTRIA DEL PAPEL"
    },
    "3231 Impresión e industrias conexas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "323 IMPRESIÓN E INDUSTRIAS CONEXAS"
    },
    "3241 Fabricación de productos derivados del petróleo y del carbón":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "324 FABRICACIÓN DE PRODUCTOS DERIVADOS DL PETRÓLEO Y CARBÓN"
    },
    "3251 Fabricación de productos químicos básicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "325 INDUSTRIA QUÍMICA"
    },
    "3252 Fabricación de resinas y hules sintéticos, y fibras químicas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "325 INDUSTRIA QUÍMICA"
    },
    "3253 Fabricación de fertilizantes, pesticidas y otros agroquímicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "325 INDUSTRIA QUÍMICA"
    },
    "3254 Fabricación de productos farmacéuticos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "325 INDUSTRIA QUÍMICA"
    },
    "3255 Fabricación de pinturas, recubrimientos y adhesivos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "325 INDUSTRIA QUÍMICA"
    },
    "3256 Fabricación de jabones, limpiadores y preparaciones de tocador":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "325 INDUSTRIA QUÍMICA"
    },
    "3259 Fabricación de otros productos químicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "325 INDUSTRIA QUÍMICA"
    },
    "3261 Fabricación de productos de plástico":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "326 INDUSTRIA DEL PLÁSTICO Y DEL HULE"
    },
    "3262 Fabricación de productos de hule":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "326 INDUSTRIA DEL PLÁSTICO Y DEL HULE"
    },
    "3271 Fabricación de productos a base de arcillas y minerales refractarios":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "327 FABRICACIÓN DE PRODUCTOS A BASE DE MINERALES NO METÁLICOS"
    },
    "3272 Fabricación de vidrio y productos de vidrio":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "327 FABRICACIÓN DE PRODUCTOS A BASE DE MINERALES NO METÁLICOS"
    },
    "3273 Fabricación de cemento y productos de concreto":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "327 FABRICACIÓN DE PRODUCTOS A BASE DE MINERALES NO METÁLICOS"
    },
    "3274 Fabricación de cal, yeso y productos de yeso":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "327 FABRICACIÓN DE PRODUCTOS A BASE DE MINERALES NO METÁLICOS"
    },
    "3279 Fabricación de otros productos a base de minerales no metálicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "327 FABRICACIÓN DE PRODUCTOS A BASE DE MINERALES NO METÁLICOS"
    },
    "3311 Industria básica del hierro y del acero":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "331 INDUSTRIAS METÁLICAS BÁSICAS"
    },
    "3312 Fabricación de productos de hierro y acero":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "331 INDUSTRIAS METÁLICAS BÁSICAS"
    },
    "3313 Industria básica del aluminio":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "331 INDUSTRIAS METÁLICAS BÁSICAS"
    },
    "3314 Industrias de metales no ferrosos, excepto aluminio":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "331 INDUSTRIAS METÁLICAS BÁSICAS"
    },
    "3315 Moldeo por fundición de piezas metálicas":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "331 INDUSTRIAS METÁLICAS BÁSICAS"
    },
    "3321 Fabricación de productos metálicos forjados y troquelados":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3322 Fabricación de herramientas de mano sin motor y utensilios de cocina metálicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3323 Fabricación de estructuras metálicas y productos de herrería":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3324 Fabricación de calderas, tanques y envases metálicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3325 Fabricación de herrajes y cerraduras":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3326 Fabricación de alambre, productos de alambre y resortes":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3327 Maquinado de piezas y fabricación de tornillos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3328 Recubrimientos y terminados metálicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3329 Fabricación de otros productos metálicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "332 FABRICACIÓN DE PRODUCTOS METÁLICOS"
    },
    "3331 Fabricación de maquinaria y equipo agropecuario, para la construcción y para la industria extractiva":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "333 FABRICACIÓN DE MAQUINARIA Y EQUIPO"
    },
    "3332 Fabricación de maquinaria y equipo para las industrias manufactureras, excepto la metalmecánica":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "333 FABRICACIÓN DE MAQUINARIA Y EQUIPO"
    },
    "3333 Fabricación de maquinaria y equipo para el comercio y los servicios":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "333 FABRICACIÓN DE MAQUINARIA Y EQUIPO"
    },
    "3334 Fabricación de equipo de aire acondicionado, calefacción, y de refrigeración industrial y comercial":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "333 FABRICACIÓN DE MAQUINARIA Y EQUIPO"
    },
    "3335 Fabricación de maquinaria y equipo para la industria metalmecánica":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "333 FABRICACIÓN DE MAQUINARIA Y EQUIPO"
    },
    "3336 Fabricación de motores de combustión interna, turbinas y transmisiones":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "333 FABRICACIÓN DE MAQUINARIA Y EQUIPO"
    },
    "3339 Fabricación de otra maquinaria y equipo para la industria en general":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "333 FABRICACIÓN DE MAQUINARIA Y EQUIPO"
    },
    "3341 Fabricación de computadoras y equipo periférico":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "334 FABRICACIÓN DE EQUIPO DE COMPUTACIÓN, COMUNICACIÓN, MEDICIÓN Y DE OTROS EQUIPOS, COMPONENTES Y ACCESORIOS ELECTRÓNICOS"
    },
    "3342 Fabricación de equipo de comunicación":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "334 FABRICACIÓN DE EQUIPO DE COMPUTACIÓN, COMUNICACIÓN, MEDICIÓN Y DE OTROS EQUIPOS, COMPONENTES Y ACCESORIOS ELECTRÓNICOS"
    },
    "3343 Fabricación de equipo de audio y de video":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "334 FABRICACIÓN DE EQUIPO DE COMPUTACIÓN, COMUNICACIÓN, MEDICIÓN Y DE OTROS EQUIPOS, COMPONENTES Y ACCESORIOS ELECTRÓNICOS"
    },
    "3344 Fabricación de componentes electrónicos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "334 FABRICACIÓN DE EQUIPO DE COMPUTACIÓN, COMUNICACIÓN, MEDICIÓN Y DE OTROS EQUIPOS, COMPONENTES Y ACCESORIOS ELECTRÓNICOS"
    },
    "3345 Fabricación de instrumentos de medición, control, navegación, y equipo médico electrónico":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "334 FABRICACIÓN DE EQUIPO DE COMPUTACIÓN, COMUNICACIÓN, MEDICIÓN Y DE OTROS EQUIPOS, COMPONENTES Y ACCESORIOS ELECTRÓNICOS"
    },
    "3346 Fabricación y reproducción de medios magnéticos y ópticos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "334 FABRICACIÓN DE EQUIPO DE COMPUTACIÓN, COMUNICACIÓN, MEDICIÓN Y DE OTROS EQUIPOS, COMPONENTES Y ACCESORIOS ELECTRÓNICOS"
    },
    "3351 Fabricación de accesorios de iluminación":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "335 FABRICACIÓN DE ACCESORIOS, APARATOS ELÉCTRICOS Y EQUIPO DE GENERACIÓN DE ENERGÍA ELÉCTRICA"
    },
    "3352 Fabricación de aparatos eléctricos de uso doméstico":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "335 FABRICACIÓN DE ACCESORIOS, APARATOS ELÉCTRICOS Y EQUIPO DE GENERACIÓN DE ENERGÍA ELÉCTRICA"
    },
    "3353 Fabricación de equipo de generación y distribución de energía eléctrica":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "335 FABRICACIÓN DE ACCESORIOS, APARATOS ELÉCTRICOS Y EQUIPO DE GENERACIÓN DE ENERGÍA ELÉCTRICA"
    },
    "3359 Fabricación de otros equipos y accesorios eléctricos":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "335 FABRICACIÓN DE ACCESORIOS, APARATOS ELÉCTRICOS Y EQUIPO DE GENERACIÓN DE ENERGÍA ELÉCTRICA"
    },
    "3361 Fabricación de automóviles y camiones":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "336 FABRICACIÓN DE EQUIPO DE TRANSPORTE"
    },
    "3362 Fabricación de carrocerías y remolques":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "336 FABRICACIÓN DE EQUIPO DE TRANSPORTE"
    },
    "3363 Fabricación de partes para vehículos automotores":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "336 FABRICACIÓN DE EQUIPO DE TRANSPORTE"
    },
    "3364 Fabricación de equipo aeroespacial":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "336 FABRICACIÓN DE EQUIPO DE TRANSPORTE"
    },
    "3365 Fabricación de equipo ferroviario":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "336 FABRICACIÓN DE EQUIPO DE TRANSPORTE"
    },
    "3366 Fabricación de embarcaciones":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "336 FABRICACIÓN DE EQUIPO DE TRANSPORTE"
    },
    "3369 Fabricación de otro equipo de transporte":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "336 FABRICACIÓN DE EQUIPO DE TRANSPORTE"
    },
    "3371 Fabricación de muebles, excepto de oficina y estantería":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "337 FABRICACIÓN DE MUEBLES, COLCHONES Y PERSIANAS"
    },
    "3372 Fabricación de muebles de oficina y estantería":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "337 FABRICACIÓN DE MUEBLES, COLCHONES Y PERSIANAS"
    },
    "3379 Fabricación de colchones, persianas y cortineros":{
            sector: "INDUSTRIA",
            subsector:"31 INDUSTRIAS MANUFACTURERAS",
            ramo: "337 FABRICACIÓN DE MUEBLES, COLCHONES Y PERSIANAS"
    },
    "3391 Fabricación de equipo no electrónico y material desechable de uso médico, dental y para laboratorio, y artículos oftálmicos":{
            sector: "INDUSTRIA",
            subsector:"33 OTRAS INDUSTRIAS MANUFACTURERAS",
            ramo: "339 OTRAS INDUSTRIAS MANUFACTURERAS"
    },
    "3399 Otras industrias manufactureras":{
            sector: "INDUSTRIA",
            subsector:"33 OTRAS INDUSTRIAS MANUFACTURERAS",
            ramo: "339 OTRAS INDUSTRIAS MANUFACTURERAS"
    }
}
