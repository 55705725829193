import React from 'react'
import NL from '../assets/imgs/nl2.svg'
import Economia from '../assets/imgs/economia.png'
import Montemorelos from '../assets/imgs/Montemorelos.png'
import Monterrey from '../assets/imgs/mty_escudo.png'
import Mty_Gob from '../assets/imgs/mty_gob.png'
import Mty_eco from '../assets/imgs/mty_economia.png'
import Montemorelos_admin from '../assets/imgs/Montemorelos_admin.png'
import { useAuth } from '../hooks/useAuth'
import { Link, NavLink } from 'react-router-dom'
export const Navbar = () => {
    const {logout, user}=useAuth()


  return (
    <nav>
    <div className="nav-links">
            {
              user && user.email && user.email.toUpperCase().includes('.MONTEMORELOS@') ?
                
                <ul>

                    <li><NavLink  className='nl_logo'  target={'_blank'} to="https://www.nl.gob.mx/"><img className='p-2' src={NL} alt="Estado de Nuevo Leon" /></NavLink>  </li>
                    {/* <li><NavLink  target={'_blank'} to="https://www.nl.gob.mx/economia"><img src={Economia} alt="Economia Nuevo Leon- Gabinete de generacion de riqueza sostenible"/> </NavLink>  </li> */}
                    <li><NavLink   to="/">Panel</NavLink> </li>
                    <li><NavLink  to="/formulario">Generar solicitud</NavLink> </li>
                    {
                      Number( user.rol) ===0 ?
                      <li><NavLink  to="/contacto">Contacto</NavLink> </li>
                      :
                      <li><NavLink  to="/reportes">Reportes</NavLink> </li>
                    }
                    <li><button onClick={logout} className="btn btn-secundary">Cerrar sesion</button> </li>
                    <li><img className='p-2' style={{padding:"0.8rem 0"}} src={Montemorelos} alt="Municipio de Montemorelos" /> </li>
                    <li><img className='p-2' style={{padding:"0.8rem 1rem"}} src={Montemorelos_admin} alt="Municipio de Montemorelos Administración"/>  </li>
                </ul>
              :
              user && user.email && user.email.toUpperCase().includes('.MONTERREY@') ?
                <ul>
                    <li><NavLink   target={'_blank'} to="https://www.nl.gob.mx/"><img  className='nl_logo p-2'  src={NL} alt="Estado de Nuevo Leon" /></NavLink>  </li>
                    {/* <li><NavLink  target={'_blank'} to="https://www.nl.gob.mx/economia"><img src={Economia} alt="Economia Nuevo Leon- Gabinete de generacion de riqueza sostenible"/> </NavLink>  </li> */}
                    <li><NavLink   to="/">Panel</NavLink> </li>
                    <li><NavLink  to="/formulario">Generar solicitud</NavLink> </li>
                    <li><NavLink  to="/reportes">Reportes</NavLink> </li>
                    
                    <li><button onClick={logout} className="btn btn-secundary">Cerrar sesion</button> </li>
                    <li><img  style={{padding:"0.8rem 0"}} src={Monterrey} alt="Municipio de Monterrey" /> </li>
                    <li><img style={{padding:"0.8rem 1rem"}} src={Mty_Gob} alt="Municipio de Monterrey Administración"/>  </li>
                    <li><img style={{padding:"0.8rem 1rem", maxWidth:'180px'}} src={Mty_eco} alt="Municipio de Monterrey Administración"/>  </li>
                </ul>
                :
                <ul>

                      <li><NavLink  target={'_blank'} to="https://www.nl.gob.mx/"><img  className='nl_logo p-2'  src={NL} alt="Estado de Nuevo Leon" /></NavLink>  </li>
                      <li><NavLink   to="/">Panel</NavLink> </li>
                      <li><NavLink  to="/formulario">Generar solicitud</NavLink> </li>
                      {
                        Number( user.rol) ===0 ?
                        <li><NavLink  to="/contacto">Contacto</NavLink> </li>
                        :
                        <li><NavLink  to="/reportes">Reportes</NavLink> </li>
                      }
                      <li><button onClick={logout} className="btn btn-secundary">Cerrar sesion</button> </li>
                      {/* <li><NavLink  target={'_blank'} to="https://www.nl.gob.mx/economia"><img src={Economia} alt="Economia Nuevo Leon- Gabinete de generacion de riqueza sostenible"/> </NavLink>  </li> */}
                  </ul>
            }
    </div>
    
        <hr />
    </nav>
  )
}
