import axios from 'axios';
import { path } from './env';


const  baseURL = path + '/admin';


const adminApi = axios.create({baseURL})

adminApi.interceptors.request.use(
    async(config)=>{
        const token =  localStorage.getItem('impulso-token')
        if (token) {
            config.headers['x-token'] =token
        }
        return config
    }
)


export default adminApi; 