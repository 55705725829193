import axios from 'axios';
import { path } from './env';


const  baseURL = path + '/form';

const formApi = axios.create({baseURL})

formApi.interceptors.request.use(
    async(config)=>{
        const token =  localStorage.getItem('impulso-token')
        if (token) {
            config.headers['x-token'] =token
        }
        return config
    }
)

export default formApi; 