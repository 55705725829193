import axios from 'axios';

import { path } from './env';


const  baseURL = path + '/dates';


const dateApi = axios.create({baseURL})

dateApi.interceptors.request.use(
    async(config)=>{
        const token =  localStorage.getItem('impulso-token')
        if (token) {
            config.headers['x-token'] =token
        }
        return config
    }
)


export default dateApi; 