import React, { useEffect, useState } from 'react'
import { Navbar } from '../components/Navbar'
import '../assets/css/form-edit.css'
import { Link, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import formApi from '../api/formApi'
import { Loading } from '../components/Loading'
import filesApi from '../api/filesApi'
import cedulasApi from '../api/cedulasApi'
import { path } from '../api/env'
import { actividades } from '../data/actividad-scian'
import adminApi from '../api/adminApi'
import { CedulaPDF } from '../components/CedulaPDF'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { validateForm } from '../helpers/validateForm'
import { useAuth } from '../hooks/useAuth'


const files = [
    {
        name:'file.comprobante_domicilio',
        input:"Comprobante De Domicilio",
        accept:'.pdf',
        max:2
    },
    {
        name:'file.acta_constitutiva',
        input:"Acta Constitutiva",
        accept:'.pdf',
        max:10
    },
    {
        name:'file.poder_notarial',
        input:"Poder Notarial",
        accept:'.pdf',
        max:10
    },
    {
        name:'file.ID_representante',
        input:"ID del representante legal",
        accept:'image/*,.pdf',
        max:2
    },
    {
        name:'file.constancia_situacion_fiscal',
        input:"Constancia Situacion Fiscal",
        accept:'.pdf',
        max:2
    },
]
const formats = ['application/pdf', 'image/jpeg', 'image/jpg']

export const DateAdmin = () => {
    const [form, setForm] = useState({})
    const [fileName, setFileName] = useState(files[0])
    const [fileMsg, setFileMsg] = useState('')
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const {state:cedula} = useLocation()
    const {  user } = useAuth()

    const [file, setFile] = useState(null)
    
    const [actividad, setActividad] = useState({SECTOR:cedula.SECTOR || "COMERCIO",SUBSECTOR:cedula.SUBSECTOR || "10 Comercio" , RAMO: cedula.RAMO || "10 Comercio", ACTIVIDAD_ECONÓMICA:cedula.ACTIVIDAD_ECONÓMICA || "10 Comercio"});
    // useEffect(() => {
    //     setForm(cedula)
    // }, [])
    const getData = async()=> {
        const {data} = await cedulasApi.get('/'+cedula.cedula)
        console.log(data)
        if (data && !data.error) {
            setForm(data)
            setActividad({SECTOR:data.SECTOR || cedula.SECTOR,SUBSECTOR:data.SUBSECTOR || cedula.SUBSECTOR , RAMO: data.RAMO || cedula.RAMO, ACTIVIDAD_ECONÓMICA:data.ACTIVIDAD_ECONÓMICA || cedula.ACTIVIDAD_ECONÓMICA})
        }

    }
    useEffect(() => {
       getData()
    }, [change])

    const handleInputEdit = (e) => {
        if (e.target.dataset.type === 'currency') {
            e.target.value= e.target.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            
        }
        if (e.target.name.includes('EXPORTACION_20')) {
            let value = e.target.value;
            value = value.replace(/[^0-9.]/g, "");
  
            // Limitar el valor a un rango de 0 a 100
            value = Math.min(Math.max(value, 0), 100);
            
            // Agregar el signo de porcentaje
            e.target.value = value + "%";
            
        }
        //numero de empleados
        const totalEmpleados = document.querySelector('input[name="TOTAL_EMPLEADOS"]');
        const totalMujeres = document.querySelector('input[name="EMPLEADOS_MUJERES"]');
        const totalHombres = document.querySelector('input[name="EMPLEADOS_HOMBRES"]'); 
        if (totalEmpleados && totalEmpleados.value > 0) {
            if (!totalHombres.value && !totalMujeres.value ) {
                totalHombres.setAttribute('max', totalEmpleados.value);
                totalMujeres.setAttribute('max', totalEmpleados.value);
            }else{
                totalHombres.removeAttribute("max")
                totalMujeres.removeAttribute("max")
            }
        }else if ( totalHombres && totalMujeres ) {
            totalEmpleados.value = Number(totalHombres.value) + Number(totalMujeres.value)
        }
        if (e.target.type === 'checkbox') {
            let checkboxValues = form[e.target.name] || [];
            console.log(checkboxValues)
            if (checkboxValues.includes(e.target.value)) {
                if (!e.target.checked) {
                    checkboxValues = checkboxValues.filter(v => v!== e.target.value)
                }
            }else{
                if (e.target.checked) {
                    checkboxValues.push(e.target.value)
                }
            }
            setForm({...form, [e.target.name]:checkboxValues})
        }else if(e.target.type === 'email'){
            setForm({...form, [e.target.name]:e.target.value})

        }else{
            setForm({...form, [e.target.name]:e.target.value.toUpperCase().replace(/,/g, "")})
        }
    }
    
    const handleEdit = (id)=> {
        console.log(id)
        const edit = document.getElementById('edit-'+id)
        const save = document.getElementById('save-'+id)
        edit.classList.add('hide')
        save.classList.remove('hide')

        const input = document.getElementById(id)
        input.disabled = false
        console.log(input)
        setChange(!change)
    }
    const handleSave = async(id)=> {
        const save = document.getElementById('save-'+id)
        const input = document.getElementById(id)
        const edit = document.getElementById('edit-'+id)
        input.disabled = true
        console.log({id})
        edit.classList.remove('hide')
        save.classList.add('hide')
        const isValid = validateForm(id,form[input.name])

        if (isValid) {
            
            const {data} = await formApi.post('/editar/'+cedula.id_form, {
                [id]:form[input.name]
            })
            
            console.log(data)
            setChange(!change)
    
    
            Swal.fire({
                position: 'center-end',
                icon: "success",
                title: "Guardado",
                text:"Guardado",
                showConfirmButton: false,
                backdrop: false,
                timer: 1000
            })
            
        }


    }

    const handleSelectFile = async(e)=>{
            const input = e.target
            console.log(input.files[0].size/1048576 )
            console.log(input.accept )
            console.log(input.files[0].type )
            console.log(input.accept.includes(input.files[0].type) )
            
            if (input.files[0].size/1048576 > (input.dataset.max-0 )) {
                setFileMsg('Archivo demasiado pesado')
            }else if ( !formats.includes(input.files[0].type) ){
                setFileMsg('Formato de archivo no permitido')
            }else  {
                setFileMsg('')
                setFile(input.files[0])
                input.setAttribute('data-before', (input.files[0].name));
            }
    }
    const handleUploadFile = async(e)=>{
        const input = document.getElementById(fileName.name.substring(5))

        e.preventDefault();
        if (file) {
            setLoading(true);
            
            try {
                const formData = new FormData();
                formData.append(fileName.name.substring(5), file)
                const {data} = await filesApi.post('/'+cedula.id_form, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (data && !data.error) {
                    Swal.fire({
                        title: "Archivos agregados correctamente",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        hideClass: {
                          popup: 'animate__animated animate__fadeOutUp'
                        }
                    })
                    
                }
            } catch (error) {
                console.log(error)
                Swal.fire({
                    title: "Error",
                    icon: 'error',
                    text: error.response?.data.msg || "Error en servidor",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
            setChange(!change)
            setFile(null)
            setLoading(false);
            input.setAttribute('data-before', "SELECCIONAR UN ARCHIVO");

        }

    }
    const handleDelete = async(e)=>{
        e.preventDefault();

        Swal.fire({
            title: 'Borrar archivo',
            text: "El archivo será borrado",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Borrar'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                    const {data} = await filesApi.post('/remover', {id_file:form.id_file, name:fileName.name.substring(5)});
                    if (data && !data.error) {
                        setChange(!change)
                        Swal.fire({
                            title: "Archivos borrado correctamente",
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                            }
                        })   
                    }
                } catch (error) {
                    Swal.fire(
                        'Error',
                        "Favor de contactar al administrador",
                        "error"
                      )
                }
            }
          })
    }

    const handleSelectActivity = (e)=>{
        const {value} = e.target
        setActividad({
            ACTIVIDAD_ECONÓMICA:value,
            SECTOR: actividades[value].sector,
            SUBSECTOR:actividades[value].subsector,
            RAMO: actividades[value].ramo
        })
    }

    const handleApprove = async()=>{
        Swal.fire({
            title: 'Aprobar solicitud',
            text: "La solicutd será aprobada",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, aprobar'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                  const body = {
                    ...actividad,
                    approved:1,
                  }
                  const {data} = await adminApi.post('/approve/'+form.id_form, body)
                  
                  console.log(data)
                  const {msg} = data
                  setChange(!change)
                  
                  Swal.fire({
                    title: msg.title,
                    text: msg.text,
                    icon: msg.type,
                    showCancelButton: false,
                    showConfirmButton: true,
                  })
                    
                } catch (error) {
                  console.log(error)
                    Swal.fire(
                        'Error',
                        "Favor de contactar al administrador",
                        "error"
                      )
                }
            }
          })
    }
    const handleDecline = async()=>{
        Swal.fire({
            title: 'Rechazar solicitud',
            text: "La solicutd será rechazada",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, rechazar'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                  const body = {
                    ...actividad,
                    approved:0,
                  }
                  const {data} = await adminApi.post('/approve/'+form.id_form, body)
                  
                  console.log(data)
                  const {msg} = data
                  setChange(!change)
                  
                  Swal.fire({
                    title: msg.title,
                    text: msg.text,
                    icon: msg.type,
                    showCancelButton: false,
                    showConfirmButton: true,
                  })
                    
                } catch (error) {
                  console.log(error)
                    Swal.fire(
                        'Error',
                        "Favor de contactar al administrador",
                        "error"
                      )
                }
            }
          })
    }
    const RenderBtn = ()=> {
        return (
            <div className="info">
            <PDFDownloadLink fileName={form.cedula} className='btn btn-primary '   document={<CedulaPDF data={form}/>} >Descargar Cédula</PDFDownloadLink>
                <div className="lds-ring hide loading"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }



  return (
    <>
        <Navbar />
        <div className="container">
            <div className="date-info"  data-id_form="{cedula.id_form }">
                <div className="info">
                    <h4>Día</h4> <p> {cedula['date.date']?cedula['date.date'] : "Sin cita" }</p> 
                </div>
                <div className="info">
                    <h4>Hora</h4> <p> {cedula['date.time']?cedula['date.time'] : "Sin cita" }</p> 
                </div>
                <div className="info">
                    <h4>Cedula</h4><p id="cedula"> {cedula.cedula }</p> 
                </div>
                <div className="info">
                    <h4>Estado</h4><p> {form.completed===0? "Sin evaluar" : "Completada"  }</p> 
                </div>
                    {
                        cedula.completed===1 &&
                        <div className="info">
                            <h4>Solicitud</h4><p > {form.approved===1? "Aprobada" : "Declinada"  }</p> 
                        </div>
                    }
                    {
                        form.approved===1 && !user.email.toUpperCase().includes('.MONTERREY@') &&  
                        RenderBtn()

                    }
             </div>
                <div className="edit-form-container">
            <section className="left">
                <div className="edit-header">
                    <h2>Instrucciones para editar</h2>
                    <ol>
                        <li>Click en el icono <i className="fa-regular fa-pen-to-square"></i></li>
                        <li>Editar el campo requerido</li>
                        <li>Click en el icono <i className="fa-regular fa-floppy-disk"></i> para guardar cambios</li>
            
                    </ol>
                </div>
                {
                     Object.keys(cedula).map((input) => {
                        if (input.includes('form.') && !input.includes('id') && !input.includes('At')) {
                            if (input && input === "form.ACTIVIDAD_ECONÓMICA"){
                                return (
                                    <React.Fragment key={input}>
                                    <div key={input} className="inputfield">
                                        <label htmlFor={input.substring(5)}>{input.substring(5)? input.substring(5).replace(/_/g, ' ') : input } </label>
                                        <span id={"error-"+input.substring(5)} className="error"></span>
                                        {
                                        
                                            <div className="input">
                                                <textarea 
                                                    name={ input } 
                                                    className="form-input" 
                                                    id={input? input.substring(5) : input } 
                                                    type="text" 
                                                    value={ form[input] || "" } 
                                                    disabled
                                                    onChange={handleInputEdit}
                                                > 
    
                                                </textarea>
                                                <button className="btn btn-secundary edit-btn" id={'edit-'+input.substring(5)} onClick={()=>handleEdit(input.substring(5)) }><i className="fa-regular fa-pen-to-square"></i></button>
                                                <button className="btn btn-secundary save-btn hide" id={'save-'+input.substring(5)} onClick={()=>handleSave(input.substring(5))} ><i className="fa-regular fa-floppy-disk"></i></button>
                                            </div>
                                        }                                
                                    </div>
                                    <div  className="inputfield activity-admin">
                                        <div className="question">
                                            <p> ACTIVIDAD ECONÓMICA </p> 
                                            <select value={ actividad.ACTIVIDAD_ECONÓMICA} onChange={handleSelectActivity} name="SECTOR" id="ACTIVIDAD_ECONÓMICA_ADMIN" required> 
                                                <option  disabled hidden  value=''></option>    
                                                {
                                                    Object.keys(actividades).map(sector=>(
                                                        <option key={sector} value={sector}>{sector}</option>    
                                                        
                                                    ))
                                                }
                                            </select>                       
                                        </div>
                                        <div className="question">
                                            <p> RAMO </p> 
                                            <input type="text"  value={actividad.RAMO} onChange={(e)=>setActividad({...actividad, RAMO:e.target.value})} />               
                                        </div>
                                        <div className="question">
                                            <p> SUBSECTOR </p> 
                                            <input type="text" value={ actividad.SUBSECTOR} onChange={(e)=>setActividad({...actividad, SUBSECTOR:e.target.value})} />               
                                        </div>
                                        <div className="question">
                                            <p> SECTOR </p> 
                                            <input type="text" value={ actividad.SECTOR} onChange={(e)=>setActividad({...actividad, SECTOR:e.target.value})} />               
                                        </div>
                                        
                                    </div>
                                    </React.Fragment>
                                    
                                )
                            }else{

                                return (
                                    <div key={input} className="inputfield">
                                        <label htmlFor={input.substring(5)}>{input.substring(5)? input.substring(5).replace(/_/g, ' ') : input } </label>
                                        <span id={"error-"+input.substring(5)} className="error"></span>
                                        {
                                        
                                            <div className="input">
                                                <textarea 
                                                    name={ input } 
                                                    className="form-input" id={input? input.substring(5) : input } 
                                                    type="text" 
                                                    value={ form[input] || "" } 
                                                    disabled
                                                    onChange={handleInputEdit}
                                                > 
    
                                                </textarea>
                                                <button className="btn btn-secundary edit-btn" id={'edit-'+input.substring(5)} onClick={()=>handleEdit(input.substring(5)) }><i className="fa-regular fa-pen-to-square"></i></button>
                                                <button className="btn btn-secundary save-btn hide" id={'save-'+input.substring(5)} onClick={()=>handleSave(input.substring(5))} ><i className="fa-regular fa-floppy-disk"></i></button>
                                            </div>
                                        }                                
                                    </div>
                                )
                            }
                        }

                     })
                }
                        
            </section>
            <section className="right files">
                <div className="files-header">
                    <h2>Archivos</h2> 

                    <ul>
                        {
                            files.map(file => (
                                <li 
                                    key={file.name}
                                    onClick={()=>setFileName(file)}
                                    className={`btn ${fileName.name === file.name ? 'active' : ''}`}
                                    data-file={ form[file.name]?form[file.name]:file.name }
                                >
                                    {file.input}
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="files-actions">
                    <div className="form-files">
                        <h4>{form[fileName.name]? 'Cambiar archivo' : 'Agregar archivo'}</h4>
                        
                        <div className="form">
                            <form   >
                                <span>{fileMsg}</span>
                                <label className="" htmlFor="constancia_situacion_fiscal"> {fileName.input}</label>
                                <input onChange={(e)=>handleSelectFile(e)} data-max={fileName.max} title=" " data-before='Seleccionar un archivo'  className="file-input" type="file" id={fileName.name.substring(5)} name={fileName.name.substring(5)} accept={fileName.accept}   required/>
                               <button type='submit' className="btn btn-primary" onClick={(e)=>handleUploadFile(e)} >{form[fileName.name]? 'Cambiar' : 'Agregar'}</button>
                            </form>
                            {
                                loading &&
                                <Loading /> 
                            }
                        </div>
                    </div>
                   
                    {
                        form[fileName.name ] && 
                        <div className="delete-files">
                            
                            <h4>Borrar archivo</h4>
                            <div className="form">
                                {
                                    <form  >
                                        <label className="" htmlFor={fileName.input}> {fileName.input}</label>
                                        <button onClick={handleDelete} className="btn btn-primary">Borrar archivo</button>
                                    </form>
                                    
                                }
                                
                            </div>
                        </div>

                    }
                        
                </div>
                    {
                         form[fileName.name ] && 
                        <div className="files-view container">
                            <iframe id={ cedula[fileName.name.substring(5)] }  width="100%" height="800" title="Comprobante de domicilio" src={ path+form[fileName.name].replace("public","")}></iframe>
                        </div>
                    }
                
                
            </section>
            
        </div>
        <aside className="actions-admin">

            <Link to="/" className="btn btn-secundary back">Regresar</Link>
            <button className="btn btn-primary aprobar" onClick={handleApprove}>Aprobar</button>
            <button className="btn btn-primary rechazar" onClick={handleDecline}>Declinar</button>
        </aside>


        


           
        
    </div>
    </>
  )
}
