import React from 'react'
import { Header } from '../components/Header'
import LogoImpulso from '../assets/imgs/IMPULSO.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import authApi from '../api/authApi';
import Swal from 'sweetalert2';
import { FormField } from '../components/form/FormField';
import { Button } from '../components/form/Button';

export const RecoverPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const email = location.state ? location.state.email : ""

    const [data, setData] = useState({ email: "", token: "", password: "" })

    const [send, setSend] = useState(false)


    useEffect(() => {
        if (!email) return
        setData({ ...data, email })
    }, [email])


    const handleChange = (e: { target: HTMLInputElement; }) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            if (data.email) {
                const { data: res } = await authApi.post('/reset', { email: data.email })
                console.log(res)
                if (res && !res.error) {
                    Swal.fire("Listo", res.msg, 'success')
                    setSend(true)

                } else {
                    Swal.fire("Error", res.msg, 'error')
                }
            }
        } catch (error: any) {
            if (error.response) {
                Swal.fire("Error", error.response.data.msg, 'error')
            } else {
                Swal.fire("Error", "Error en el servidor", 'error')
            }
        }
    }
    const resetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            if (data.token && data.password) {
                const { data: res } = await authApi.post('/reset/' + data.token, { email: data.email, password: data.password })
                console.log(res)
                if (res && !res.error) {
                    Swal.fire("Listo", res.msg, 'success')
                    navigate('/')
                } else {
                    Swal.fire("Error", res.msg, 'error')
                }
            }
        } catch (error: any) {
            if (error.response) {
                Swal.fire("Error", error.response.data.msg, 'error')
            } else {
                Swal.fire("Error", "Error en el servidor", 'error')
            }
        }
    }


    return (
        <>
            <Header />
            <div className="w-1/3 self-center" >
                    <div className="rounded-md shadow-lg p-5 flex flex-col bg-gray-50 " >
                            <img className='w-24 self-center' src={LogoImpulso} alt="IMPULSO" />
                        <div className="form-action my-5">
                            <h1>CAMBIO DE CONTRASEÑA</h1>
                        </div>

                        {!send ?
                            <form className="login-form flex flex-col" onSubmit={sendEmail} >
                                <FormField label={"Correo"} name={"email"} onChange={handleChange} required={true} type={"email"} value={data.email} />
                                <Button disabled={false} onClick={()=>{}} text={"Solicitar"} type={"submit"} styles={"self-end"}/>
                            </form>
                            :
                            <form className="login-form" onSubmit={resetPassword}>
                                <span>Ingresa el token que se ha enviado a tu correo <b>{data.email}</b></span>
                                <span>Si quieres cambiar la dirección o no recibiste el correo pulsa <button type='button' onClick={() => setSend(false)} className='btn btn-secundary'>aquí</button> </span>

                                <div className="form-field">
                                    <label htmlFor="reset-email">TOKEN</label>
                                    <input type="text" onChange={handleChange} name="token" id="reset-token" required />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="reset-password">Nueva contraseña</label>
                                    <input type="password" onChange={handleChange} name="password" id="reset-password" required />
                                </div>
                                <button className="btn btn-primary" type="submit">Cambiar</button>
                            </form>



                        }



                    </div>






            </div>
        </>
    )
}
